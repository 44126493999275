<template>
  <div class="wrapper">
    <menu-ver
      type="course"
      :content="temas"
      v-on:theme="selectTheme"
      v-on:material="seeMaterial"
      profesor=""
    />
    <div class="container m-3">
      <div class="table-header"></div>
      <!-- Bloques temario -->
      <div v-if="selectedTheme">
        <div class="mb-5">
          <h3>{{ selectedTheme.tema_titulo }}</h3>
          <span>{{ selectedTheme.tema_descripcion }}</span>
        </div>
        <div class="mt-4" v-for="blo in bloques" :key="blo.bloque_id">
          <div class="card">
            <h4 class="bloque-title card-header">
              {{ blo.bloque_titulo }}
            </h4>
            <div class="card-body">
              <p class="card-text">{{ blo.bloque_descripcion }}</p>
              <button
                class="button"
                @click="openBlock(blo)"
                :style="{ backgroundColor: colorCompany }"
                v-if="blo.estado === 1"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
              <button
                class="button"
                @click="openBlock(blo)"
                :style="{ backgroundColor: '#ff8888' }"
                v-if="blo.estado === 2"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
              <button
                class="button"
                :style="{ backgroundColor: '#999' }"
                v-if="blo.estado === 0"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Materiales -->
      <div v-if="selectedMaterial">
        <div class="mb-5 mt-5">
          <h3>{{ $t("mUser.course.material") }}</h3>
        </div>
        <div class="mt-4">
          <table class="table" v-if="materiales && materiales.length > 0">
            <thead>
              <tr>
                <th scope="col">{{ $t("maintenance.languages.name") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in materiales" :key="item.mate_id">
                <td>{{ item.mate_nombre }}</td>
                <td>
                  <a
                    class="btn button buttonEdit"
                    target="_blank"
                    :href="`${servidor}api/ver_archivo.asp?mate_id=${item.mate_id}`"
                  >
                    <i class="fas fa-download"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Bloque -->
  <div
    class="modal fade"
    id="blockModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-edit">
      <div class="modal-content" :style="{ height: heightModal }">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ bloque.bloque_titulo }}
          </h5>
          <a
            v-if="downloadFile"
            :href="downloadFile"
            target="_blank"
            class="button downloadButton"
            ><i class="fas fa-download"></i
          ></a>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refreshCourse"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="bloque.bloque_tipo === 'PDF'">
            <iframe
              :src="dataDoc"
              type="application/pdf"
              style="width: 100%"
              :style="{ height: heightObject }"
              id="docupdf"
              pluginspage="http://www.adobe.com/products/acrobat/readsetp2.html"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'OFFICE'">
            <iframe
              :src="dataDoc"
              style="width: 100%"
              :style="{ height: heightObject }"
              frameborder="0"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'EXAM'">
            <iframe
              :src="dataDoc"
              style="width: 100%"
              :style="{ height: heightObject }"
              frameborder="0"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'VIDEO'">
            <video
              autoplay
              controls
              id="myVideo"
              style="width: 100%"
              :style="{ height: heightObject }"
            >
              <source :src="dataDoc" :type="tipoVideo" />
            </video>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'YOUTUBE'">
            <iframe
              style="width: 100%"
              :style="{ height: heightObject }"
              :src="bloque.bloque_ruta_youtube"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      course: {},
      id: this.$route.params.id,
      temas: [],
      bloques: [],
      bloque: {},
      selectedTheme: null,
      dataDoc: "",
      tipoVideo: "",
      heightModal: "",
      heightObject: "",
      downloadFile: null,
      materiales: [],
      selectedMaterial: false,
    };
  },
  created() {
    this.getThemes();
    this.getTitleCurse();
    this.changeBreadCrumb(13);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor", "colorCompany"]),
  },
  mounted() {
    this.matchHeight();
    window.addEventListener("resize", this.matchHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.matchHeight);
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop", "changeTitleCourse"]),
    async getTitleCurse() {
      try {
        const listado = {
          curso_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=curso&ac=titulo`,
          listado
        );
        // console.log(res);
        let title = "";
        if (res.data) {
          title = res.data.data[0].curso_titulo;
        }
        // console.log(title);
        this.changeTitleCourse(title);
      } catch (error) {
        console.error(error);
      }
    },
    matchHeight() {
      const w = window.innerHeight - 77;
      const y = window.innerHeight - 187;
      // console.log("altura", w);
      this.heightModal = w.toString() + "px";
      this.heightObject = y.toString() + "px";
      // console.log(this.heightModal);
    },
    async getThemes() {
      try {
        const listado = {
          tema_curso_cd: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=curso&ac=temas`,
          listado
        );
        // console.log("temas", res);
        if (res.data) {
          this.temas = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getBlocks() {
      try {
        const listado = {
          bloque_tema_cd: this.selectedTheme.tema_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=curso&ac=bloques`,
          listado
        );
        // console.log("blqoues", res);
        if (res.data) {
          this.bloques = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMaterial() {
      try {
        const listado = {
          mate_curso_cd: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=material&ac=listado`,
          listado
        );
        // console.log("material", res);
        if (res.data) {
          this.materiales = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    selectTheme(item) {
      this.selectedMaterial = false;
      this.selectedTheme = item;
      this.getBlocks();
    },
    seeMaterial() {
      this.selectedMaterial = true;
      this.selectedTheme = false;
      this.getMaterial();
    },
    openBlock(bloque) {
      this.dataDoc = null;
      this.bloque = bloque;
      // console.log("BLOQUE", bloque);
      if (this.bloque.bloque_tipo === "PDF") {
        this.dataDoc = `${this.servidor}ver_pdf_su.asp?t=bloqprof&p=${bloque.bloque_id} #toolbar=0`;
        this.downloadFile = `${this.servidor}ver_pdf_su.asp?t=bloqprof&p=${bloque.bloque_id}`;
      } else if (this.bloque.bloque_tipo === "OFFICE") {
        // FALTA TAMBIÉN REFRESCAR TEMAS Y BLOQUES AL CERRAR EL MODAL. BLOQUES CREO QUE OK PERO TEMAS CREO
        // QUE ES MÁS COMPLICADO
        this.downloadFile = `${this.servidor}descargar_office.asp?t=bloqprof&p=${bloque.bloque_id}`;
        const sUrlEnc = `${this.servidor}cursos/${bloque.bloque_fichero}`;
        this.dataDoc = `https://view.officeapps.live.com/op/embed.aspx?src=${sUrlEnc}`;
      } else if (this.bloque.bloque_tipo === "EXAM") {
        this.dataDoc = `${this.servidor}ver_examen_su.asp?t=bloqprof&p=${bloque.bloque_id}`;
        this.downloadFile = null;
      } else if (this.bloque.bloque_tipo === "VIDEO") {
        this.dataDoc = `${this.servidor}ver_video_su.asp?t=bloqprof&p=${bloque.bloque_id}`;
        this.downloadFile = `${this.servidor}ver_pdf_su.asp?t=bloqprof&p=${bloque.bloque_id}`;
        if (bloque.bloque_fichero.includes(".mp4")) {
          this.tipoVideo = "video/mp4";
        } else if (bloque.bloque_fichero.includes(".mov")) {
          this.tipoVideo = "video/quicktime";
        } else if (bloque.bloque_fichero.includes(".avi")) {
          this.tipoVideo = "video/x-msvideo";
        } else if (bloque.bloque_fichero.includes(".wmv")) {
          this.tipoVideo = "video/x-ms-wmv";
        } else if (bloque.bloque_fichero.includes(".3gp")) {
          this.tipoVideo = "video/3gpp";
        } else if (bloque.bloque_fichero.includes(".webm")) {
          this.tipoVideo = "video/webm";
        } else if (bloque.bloque_fichero.includes(".ogg")) {
          this.tipoVideo = "video/ogg";
        }
      }
      $("#blockModal").modal("show");
    },
    refreshCourse() {
      this.getThemes();
      this.getBlocks();
      let video = document.getElementById("myVideo");
      if (video) {
        video.pause();
      }      
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bloque-title {
  cursor: pointer;
}

.modal-edit {
  width: 99%;
  max-width: none;
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.downloadButton {
  border: 2px solid;
  margin-left: 20pt;
}
</style>